import React from 'react';
import { fragments, getFragments } from '../../../get_server_query';
import { format, renderServerComponents } from '../../components/cms-modules';
import WDIRankingFilterList from '../../components/wdi-ranking/wdiFilterList';
import Layout from '../../modules/layout';

const IFRankingSSR = ({ serverData: { page, locale, queryParams } }) => {
  const {
    title,
    seoDescription,
    heroTeaser,
    heroCarouselItems,
    heroCarouselTeaser,
    showAnchorLinks,
    filterlist,
    content,
  } = page;

  const screenshotURL = `${process.env.GATSBY_DOMAIN}${process.env.GATSBY_SCREENSHOT_SERVICE_URL}`;
  const websiteURL = `${process.env.GATSBY_DOMAIN}/${locale}/if-design-ranking?${queryParams}`;

  return (
    <Layout
      title={title}
      description={seoDescription}
      heroTeaser={heroTeaser}
      heroCarouselItems={heroCarouselItems}
      heroCarouselTeaser={heroCarouselTeaser}
      previewImage={`${screenshotURL}?url=${encodeURIComponent(
        websiteURL
      )}&anchorId=resultList&viewportWidth=1280&viewportHeight=1280`}
      anchorLinks={
        showAnchorLinks
          ? content
              .filter((component) => component.anchorLabel)
              .map((component) => ({
                label: component.anchorLabel,
                anchorId: component.description,
              }))
          : []
      }
    >
      {content && renderServerComponents(content, locale)}
      {filterlist == 'iF Ranking' && <WDIRankingFilterList />}
    </Layout>
  );
};

export async function getServerData(ctx) {
  const locale = ctx.pageContext.locale || 'en';
  const gqlUrl = `${process.env.GATSBY_CONTENTFUL_GRAPHQL_URL}/${process.env.GATSBY_CONTENTFUL_SPACE_ID}/environments/${process.env.GATSBY_CONTENTFUL_ENVIRONMENT}`;
  const authHeader = `Bearer SOEGLctP3y-WLjmF6YIv1juHhNn9dOBOvp98vtQuFnY`;
  const queryParams = new URLSearchParams(ctx.query).toString();

  const getPageContentTypes = (locale = 'en') => `
  {
    pageCollection(where: {slug: "if-design-ranking"}, limit: 1, locale: "${locale}") {
      items {
        content: contentCollection(limit: 10) {
          items {
            __typename
          }
        }

      }
    }
  }
  `;

  const typesResponse = await fetch(gqlUrl, {
    method: 'POST',
    body: JSON.stringify({
      operationName: null,
      query: getPageContentTypes(locale),
      variables: {},
    }),
    headers: {
      Authorization: authHeader,
      'Content-Type': 'application/json',
    },
  });
  const typesJson = await typesResponse.json();
  const includedTypes =
    typesJson.data?.pageCollection?.items[0].content?.items.map(
      (el) => el.__typename
    );

  const getPage = (locale = 'en', includedTypes = []) => {
    return `
  ${getFragments([...includedTypes, 'ContentHeroStage']).replace(
    /(\W)\s*/g,
    '$1'
  )}
  fragment accordeonWrapperInsideTextVisual on ContentAccordeonWrapper {
    headline {
      raw: json
    }
    subline {
      raw: json
    }
    content: contentCollection(limit: 2) {
      items {
        ...accordeonItemInsideTextVisual
      }
    }
    anchorLabel
    cta {
      ...link
    }
    beigeBackground
    __typename
    description
  }
  fragment accordeonItemInsideTextVisual on ContentAccordeonItem {
    headline
    content: contentCollection(limit: 2) {
      items {
        ... on ContentTextVisualWrapper {
          headline {
            raw: json
          }
          beigeBackground
          spacing
          __typename
          description
          mobileLayout
          anchorLabel
        }
      }
    }
    __typename
  }
  query {
    pageCollection(where: {slug: "if-design-ranking"}, limit: 1, locale: "${locale}") {
      items {
        title
        slug
        seoDescription
        heroTeaser {
          ...hero
        }
        heroCarouselTeaser {
          ...heroTeaser
        }
        heroCarousel: heroCarouselCollection(limit: 6)  {
          items {
            ...hero
          }
        }
        content: contentCollection(limit: 15) {
          items {
            ${Array.from(new Set(includedTypes))
              .map((type) => `...${fragments[type]?.name}`)
              .join(' ')}
          }
        }
        hasWhiteNavigation
        metaNaviWhite
        footerColor
        external
        hideHeaderAndFooter
        filterlist
        __typename
      }
    }
  }
  `;
  };

  const query = getPage(locale, includedTypes);

  const res = await fetch(gqlUrl, {
    method: 'POST',
    body: JSON.stringify({
      operationName: null,
      query,
      variables: {},
    }),
    headers: {
      Authorization: authHeader,
      'Content-Type': 'application/json',
    },
  });

  const json = await res.json();
  const page = format(json.data.pageCollection.items[0]);

  return {
    status: 200,
    props: { page, locale, queryParams },
  };
}

export default IFRankingSSR;
